import React from "react"

export const useLoader = ({ isActive, frames, resolution, orientation }) => {
  const myFrames = React.useRef([])
  const [loadedFrames, setLoadedFrames] = React.useState([])

  /* cb when frames change */
  React.useEffect(() => {
    myFrames.current = frames.map(f => f)
    setLoadedFrames([])
  }, [frames])

  /* cb when a frame was loaded */
  const onLoad = React.useCallback(
    frame => {
      const f = myFrames.current.find(f => f.id === frame.id)
      f.srcSet[orientation][resolution].loaded = true
      setLoadedFrames(
        myFrames.current
          .filter(f => f.srcSet[orientation][resolution].loaded === true)
          .map(f => f.srcSet[orientation][resolution].src)
      )
    },
    [myFrames, setLoadedFrames, orientation, resolution]
  )

  /* prefetch images */
  React.useEffect(() => {
    if (!isActive || !orientation || typeof resolution === "undefined") return
    const imgs = []
    myFrames.current.forEach(frame => {
      const src = frame.srcSet[orientation][resolution].src
      if (!src) return
      const img = new Image()
      imgs.push(img)
      img.onerror = () => {
        myFrames.current = myFrames.current.filter(f => f.id !== frame.id)
        img.onload = null
        img.onerror = null
      }
      img.onload = () => {
        onLoad(frame)
        img.onload = null
        img.onerror = null
      }
      img.src = src
      if (img.complete) {
        img.onload()
      }
    })
    return () => {
      imgs.forEach(img => {
        img.onload = null
        img.onerror = null
      })
    }
  }, [myFrames, isActive, resolution, orientation, onLoad])

  return {
    loadedFrames: loadedFrames,
    complete: loadedFrames.length === myFrames.current.length,
    max: myFrames.current.length,
  }
}
