import React from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"

import SvgContainer from "../components/SvgContainer"

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const Arrow = () => {
  const [pos, setPos] = React.useState(0)

  React.useEffect(() => {
    let timeout = 0
    timeout = setTimeout(
      () => {
        setPos((pos + 1) % 4)
      },
      pos === 0
        ? 1000
        : pos === 1
        ? 100
        : pos === 2
        ? 300
        : pos === 3
        ? 100
        : 1000
    )
    return () => clearTimeout(timeout)
  }, [pos])

  return (
    <motion.div
      animate={{
        opacity: pos === 2 ? 1 : 0,
        y: pos < 2 ? "0em" : "1em",
        transition: { duration: 0.2 },
      }}
      css={theme => css`
        z-index: 999;
        bottom: 3em;
        left: ${theme.pagePadding[3]};
        width: 1em;
      `}
    >
      <SvgContainer width={16} height={12} title="Scroll">
        <path
          d="M 0 0 L 16 0 L 8 12 Z"
          css={theme =>
            css`
              fill: ${theme.color.fg};
            `
          }
        />
      </SvgContainer>
    </motion.div>
  )
}

const Header = ({ children }) => (
  <InView threshold={0.75}>
    {({ inView, ref }) => (
      <div
        ref={ref}
        css={css`
          height: 100vh;
        `}
      >
        <motion.header
          variants={variants}
          animate={inView ? "visible" : "hidden"}
          css={theme => css`
            pointer-events: none;
            position: fixed;
            z-index: 99;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: ${theme.pagePadding.join(" ")};
            display: flex;
            flex-flow: column nowrap;
          `}
        >
          <div
            css={theme => css`
              position: absolute;
              top: 0;
              left: 0;
              width: ${theme.pagePadding[1]};
              bottom: 0;
              background: ${theme.color.bg};
            `}
          />
          <div
            css={theme => css`
              position: absolute;
              top: 0;
              right: 0;
              width: ${theme.pagePadding[1]};
              bottom: 0;
              background: ${theme.color.bg};
            `}
          />
          <div
            css={theme => css`
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              height: ${theme.pagePadding[2]};
              background: ${theme.color.bg};
            `}
          />
          <h1
            css={theme => css`
              font-size: 100%;
              margin: 0;
              display: flex;
            `}
          >
            <SvgContainer width={798.141} height={339.069} title="EXO">
              <g fill="#F79E8D">
                <ellipse cx="634.179" cy="169.308" rx="135.001" ry="144.803" />
                <path d="M-11-8.931v357h820v-357H-11zm193.839 340.864H.165V6.683h180.447v24.059H28.68v116.734h135.446v24.059H28.68v136.338h154.159v24.06zm256.189 0L337.889 185.347h-.446L236.304 331.933h-31.188l116.733-168.417L212.244 6.683h31.634l93.565 135.001h.446L431.454 6.683h31.634L353.483 163.516l116.733 168.417h-31.188zm195.152 6.683c-85.546 0-163.962-61.486-163.962-169.308C470.218 61.485 548.634 0 634.18 0c85.545 0 163.961 61.485 163.961 169.308 0 107.822-78.416 169.308-163.961 169.308z" />
              </g>
            </SvgContainer>
          </h1>
          <div
            css={theme =>
              css`
                flex: 1 1 auto;
                padding: ${theme.pagePadding[0]} 0 ${theme.pagePadding[2]};
                background: ${theme.color.bg};
              `
            }
          >
            {children}
            <Arrow />
          </div>
        </motion.header>
      </div>
    )}
  </InView>
)

export default Header
