import React from "react"
import { createPortal } from "react-dom"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { motion, AnimatePresence } from "framer-motion"

import { useLoader } from "./hooks"
import Player from "./Player"

const fitDim = (width, height, srcSet) => {
  const elementAspect = (1.0 / width) * height
  const aspect = elementAspect >= 1 ? 1 : (1 / 16) * 9
  const finalSize =
    (elementAspect >= 1 ? height : width) * (window.devicePixelRatio || 1)
  const orientation = elementAspect >= 1 ? "square" : "landscape"
  let resolution = 0
  srcSet[orientation].find((set, i) => {
    resolution = i
    return set.dim >= finalSize
  })
  const rect = {
    orientation: orientation,
    resolution: resolution,
  }
  if (aspect > elementAspect) {
    rect.width = Math.round(width)
    rect.height = Math.round(width * aspect)
  } else {
    rect.width = Math.round(height / aspect)
    rect.height = Math.round(height)
  }
  return rect
}

const Sequence = ({ name, frames, pos, isActive, onProgress, ...props }) => {
  const [dim, setDim] = React.useState(null)
  const ref = React.useRef()
  const { loadedFrames, max, complete } = useLoader({
    ...props,
    ...dim,
    frames: frames,
    isActive: isActive,
  })

  React.useEffect(() => {
    isActive && max && onProgress((1.0 / max) * loadedFrames.length)
  }, [isActive, loadedFrames.length, max, onProgress])

  /* get resolution based on div dimensions, transform image for cover mode */
  React.useEffect(() => {
    if (!isActive || !frames.length || typeof window === "undefined") return
    const onResize = () => {
      const { width, height } = ref.current.getBoundingClientRect()
      setDim(fitDim(width, height, frames[0].srcSet))
    }
    window.addEventListener("resize", onResize)
    onResize()
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [ref, isActive, frames])

  return typeof window === "undefined"
    ? null
    : createPortal(
        <div
          ref={ref}
          css={theme => css`
            position: fixed;
            z-index: 1;
            top: ${theme.pagePadding[0]};
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            background-color: ${isActive ? theme.color.fg : "transparent"};
            transition: all 0.3s ease;

            @media (min-width: ${theme.mobileBreakpoint}) {
              right: ${theme.pagePadding[1]};
              bottom: ${theme.pagePadding[2]};
              left: ${theme.pagePadding[3]};
            }
          `}
          {...props}
        >
          <AnimatePresence>
            {complete && isActive && (
              <motion.div
                key="player"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Player frames={loadedFrames} pos={pos} dim={dim} />
              </motion.div>
            )}
            {!complete && isActive && (
              <motion.div
                key="loader"
                initial={{ opacity: 1, scaleX: 0 }}
                animate={{
                  opacity: 1,
                  scaleX: (1 / max) * loadedFrames.length,
                }}
                exit={{ opacity: 0 }}
                css={theme => css`
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  transform-origin: center left;
                  background: ${theme.color.bg};
                `}
              ></motion.div>
            )}
          </AnimatePresence>
        </div>,
        document.body
      )
}

export default React.memo(Sequence)
