import React from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Howl } from "howler"

import Picker from "./Picker"

const loops = [
  {
    name: "Averof",
    file: "Averof",
    duration: 26425.736961451246,
    offset: 51.2,
  },
  { name: "Byz", file: "Byz", duration: 17505.238095238095, offset: 56.3 },
  {
    name: "Disbander",
    file: "Disbander",
    duration: 20094.08163265306,
    offset: 51.38,
  },
  {
    name: "Ghost Light",
    file: "Ghost-Light2",
    duration: 15350.544217687075,
    offset: 51.48,
  },
  { name: "Gramm", file: "Gramm", duration: 17858.639455782313, offset: 51.39 },
  {
    name: "Passage",
    file: "Passage",
    duration: 15883.333333333333,
    offset: 51.31,
  },
  {
    name: "Slacken",
    file: "Slacken2",
    duration: 19953.26530612245,
    offset: 50.85,
  },
  { name: "Valve", file: "Valve", duration: 15018.027210884354, offset: 49.3 },
]

const Icon = () => {
  const [pos, setPos] = React.useState(0)
  React.useEffect(() => {
    let timeout = 0
    const on = () => {
      setPos(1)
      setTimeout(off, 1000)
    }
    const off = () => {
      setPos(0)
      setTimeout(on, 1000)
    }
    on()
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <svg
      css={css`
        display: block;
        margin-bottom: 0.4em;
      `}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      {pos === 1 && (
        <path d="M7.6 2.6L6.2 1.2C4.2 3.2 3 6 3 9s1.2 5.8 3.2 7.8l1.4-1.4C6 13.7 5 11.5 5 9s1-4.7 2.6-6.4z" />
      )}
      <path d="M17 20c-.3 0-.6-.1-.8-.2-.7-.4-1.2-.9-1.7-2.4-.5-1.6-1.5-2.3-2.4-3-.8-.6-1.6-1.2-2.3-2.5C9.3 11 9 9.9 9 9c0-2.8 2.2-5 5-5s5 2.2 5 5h2c0-3.9-3.1-7-7-7S7 5.1 7 9c0 1.3.4 2.7 1.1 3.9.9 1.6 2 2.5 2.8 3.1.8.6 1.4 1.1 1.7 2 .6 1.8 1.4 2.8 2.7 3.6.5.2 1.1.3 1.6.3 2.2 0 4-1.8 4-4h-2c.1 1.2-.8 2.1-1.9 2.1z" />
      <circle cx="14" cy="9" r="2.5" />
    </svg>
  )
}

const Audio = ({ file, duration, offset, isCurrent, isPlaying }) => {
  const canPlayOgg = React.useRef()
  const sound = React.useRef(null)
  const id = React.useRef(null)

  React.useEffect(() => {
    const a = document.createElement("audio")
    canPlayOgg.current = a.canPlayType("audio/ogg") !== ""
  }, [])

  const fadeIn = React.useCallback(() => {
    if (!sound.current) {
      sound.current = new Howl({
        src: [`/audio/${file}.ogg`, `/audio/${file}.mp3`],
        loop: true,
        volume: 1,
        sprite: {
          sprite: [canPlayOgg.current ? 0 : offset, duration],
        },
      })
      sound.current.once("load", () => {
        id.current = sound.current.play("sprite")
        fadeIn()
      })
    } else {
      sound.current.fade(0, 1, 1000, id.current)
    }
  }, [sound, id, file, duration, offset])

  const fadeOut = React.useCallback(() => {
    if (!sound.current) return
    sound.current.fade(1, 0, 1000, id.current)
  }, [sound, id])

  React.useEffect(() => {
    if (isCurrent && isPlaying) {
      fadeIn()
    } else if (!isCurrent && isPlaying) {
      fadeOut()
    }
  }, [sound, isCurrent, isPlaying, fadeIn, fadeOut])
  return null
}

const AudioPlayer = ({ children, ...props }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [current, setCurrent] = React.useState(0)

  const onPick = React.useCallback(index => {
    setCurrent(index)
  }, [])

  const onOpen = React.useCallback(() => {
    if (!isPlaying) setIsPlaying(true)
  }, [isPlaying])

  return (
    <>
      <Picker
        items={loops.map((loop, i) => ({
          id: i,
          title: loop.name.replace("-", " "),
          isCurrent: i === current,
        }))}
        icon={<Icon />}
        iconText={isPlaying ? loops[current].name.replace("-", " ") : "Off"}
        onPick={onPick}
        onOpen={onOpen}
        currentPicked={current}
      />
      {loops.map(({ name, file, duration, offset }, index) => (
        <Audio
          key={index}
          name={name}
          file={file}
          duration={duration}
          offset={offset}
          index={index}
          isCurrent={index === current}
          isPlaying={isPlaying}
        />
      ))}
    </>
  )
}

export default React.memo(AudioPlayer)
