import React from "react"
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import Sequence from "./Sequence"
import Picker from "../Picker"

async function supportsWebp() {
  if (typeof createImageBitmap === "undefined") return false
  const webpData =
    "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA="
  const blob = await fetch(webpData).then(r => r.blob())
  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}
let srcType = "src"
let srcSetType = "srcSet"
;(async () => {
  if (await supportsWebp()) {
    srcType = "srcWebp"
    srcSetType = "srcSetWebp"
  }
})()

const setToObject = set =>
  set.split(",").map(line => {
    const [src, dim] = line.split(" ")
    return {
      dim: parseInt(dim),
      src: src.trim(),
    }
  })

const dataToSequences = data => {
  const sequences = {}
  data.frames.edges.forEach(edge => {
    const name = edge.node.relativeDirectory
    if (!sequences[name]) {
      sequences[name] = {
        name: name,
        frames: [],
        id: edge.node.id,
      }
    }
    sequences[name].frames.push({
      id: edge.node.id,
      srcSet: {
        landscape: setToObject(edge.node.childImageSharp.fluid[srcSetType]),
        square: [
          {
            dim: 540,
            src: edge.node.childImageSharp.square540[srcType],
          },
          {
            dim: 810,
            src: edge.node.childImageSharp.square810[srcType],
          },
          {
            dim: 1080,
            src: edge.node.childImageSharp.square1080[srcType],
          },
        ],
      },
    })
  })
  return sequences
}

const Icon = () => {
  const [pos, setPos] = React.useState(0)
  React.useEffect(() => {
    let timeout = 0
    const on = () => {
      setPos(1)
      setTimeout(off, Math.random() * 3000 + 1000)
    }
    const off = () => {
      setPos(0)
      setTimeout(on, 100)
    }
    on()
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <svg
      css={[
        css`
          display: block;
          margin-bottom: 0.4em;
        `,
        pos === 0
          ? css`
              transform: scaleY(0.1);
            `
          : null,
      ]}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 105c-101.8 0-188.4 62.4-224 151 35.6 88.6 122.2 151 224 151s188.4-62.4 224-151c-35.6-88.6-122.2-151-224-151zm0 251.7c-56 0-101.8-45.3-101.8-100.7S200 155.3 256 155.3 357.8 200.6 357.8 256 312 356.7 256 356.7zm0-161.1c-33.6 0-61.1 27.2-61.1 60.4s27.5 60.4 61.1 60.4 61.1-27.2 61.1-60.4-27.5-60.4-61.1-60.4z"></path>
    </svg>
  )
}

export default ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      frames: allFile(
        filter: { relativeDirectory: { regex: "/sequence/(.*)/" } }
        sort: { fields: [name] }
      ) {
        edges {
          node {
            id
            relativeDirectory
            childImageSharp {
              fluid(
                toFormat: JPG
                jpegQuality: 30
                webpQuality: 30
                jpegProgressive: false
              ) {
                srcSet
                srcSetWebp
              }
              square1080: fixed(
                fit: COVER
                width: 1080
                height: 1080
                toFormat: JPG
                jpegQuality: 60
                webpQuality: 60
                jpegProgressive: false
                cropFocus: CENTER
              ) {
                src
                srcWebp
              }
              square810: fixed(
                fit: COVER
                width: 810
                height: 810
                toFormat: JPG
                jpegQuality: 30
                webpQuality: 30
                jpegProgressive: false
                cropFocus: CENTER
              ) {
                src
                srcWebp
              }
              square540: fixed(
                fit: COVER
                width: 540
                height: 540
                toFormat: JPG
                jpegQuality: 30
                webpQuality: 30
                jpegProgressive: false
                cropFocus: CENTER
              ) {
                src
                srcWebp
              }
            }
          }
        }
      }
    }
  `)

  // convert to proper format
  const sequences = React.useRef(dataToSequences(data))

  const [loadingProgress, setLoadingProgress] = React.useState(0)
  const [pos, setPos] = React.useState(0) // normalized animation position
  const scrollPos = React.useRef(-9999)
  const lastScrollPos = React.useRef(
    typeof window !== "undefined" ? window.pageYOffset : 0
  )

  /* initial load, setup scrolling */
  const raf = React.useRef(0)
  React.useEffect(() => {
    if (typeof window === "undefined") return
    const anim = () => {
      scrollPos.current = window.pageYOffset
      if (scrollPos.current !== lastScrollPos.current) {
        lastScrollPos.current = scrollPos.current
        setPos(
          Math.max(
            0,
            Math.min(
              1.0,
              (1.0 / (document.body.clientHeight - window.innerHeight)) *
                window.pageYOffset
            )
          )
        )
      }
      raf.current = requestAnimationFrame(anim)
    }
    raf.current = requestAnimationFrame(anim)
    return () => {
      cancelAnimationFrame(raf.current)
    }
  }, [])
  const [current, setCurrent] = React.useState(0)
  const onPick = React.useCallback(setCurrent, [])
  return (
    <>
      <Picker
        icon={<Icon />}
        items={Object.keys(sequences.current).map((k, i) => {
          const seq = sequences.current[k]
          return {
            id: seq.id,
            title: seq.name,
            isCurrent: current === i,
            src: seq.frames[0].srcSet.landscape[0].src,
          }
        })}
        onPick={onPick}
        currentPicked={current}
        progress={loadingProgress}
        iconText={current + 1 + "/" + Object.keys(sequences.current).length}
        hasLoader
        {...props}
      />
      {Object.keys(sequences.current).map((k, i) => {
        const seq = sequences.current[k]
        return (
          <Sequence
            key={seq.name}
            isActive={i === current}
            name={seq.name}
            frames={seq.frames}
            pos={pos}
            onProgress={setLoadingProgress}
          />
        )
      })}
    </>
  )
}
