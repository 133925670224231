/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import PropTypes from "prop-types"
import Header from "../components/Header"
import Nav from "../components/Nav"
import SvgContainer from "../components/SvgContainer"

const theme = {
  color: { fg: "#231F20", bg: "#f79e8d", special: "#4d190f" },
  pagePadding: ["3em", "2em", "2em", "2em"],
  maxWidth: "1200em",
  mobileBreakpoint: "40em",
  resetButton: theme => css`
    appearance: none;
    font: inherit;
    background: transparent;
    color: inherit;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:focus,
    &:active {
      outline: none;
    }
  `,
}

const Layout = ({ headerTitle, nav, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          @font-face {
            font-family: "BrownPro";
            src: url("/font/BrownPro/lineto-brown-regular.eot");
            src: url("/font/BrownPro/lineto-brown-regular.eot?#iefix")
                format("embedded-opentype"),
              url("../font/BrownPro/lineto-brown-regular.woff") format("woff"),
              url("../font/BrownPro/lineto-brown-regular.ttf")
                format("truetype");
            font-weight: 400;
            font-style: normal;
          }

          @font-face {
            font-family: "BrownPro";
            src: url("/font/BrownPro/lineto-brown-bold.eot");
            src: url("/font/BrownPro/lineto-brown-bold.eot?#iefix")
                format("embedded-opentype"),
              url("../font/BrownPro/lineto-brown-bold.woff") format("woff"),
              url("../font/BrownPro/lineto-brown-bold.ttf") format("truetype");
            font-weight: 600;
            font-style: normal;
          }

          html,
          body {
            margin: 0;
            padding: 0;
            font-size: 100%;
            width: 100%;
          }

          body {
            font-family: "BrownPro";
            font-weight: 400;
            overflow-x: hidden;
            overflow-y: scroll;
            background: ${theme.color.bg};
            color: ${theme.color.fg};
          }
        `}
      />
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: ${theme.pagePadding[0]};
          z-index: 999;
          background: ${theme.color.bg};
        `}
      >
        <Nav items={nav} />
      </div>
      <footer
        css={css`
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: ${theme.pagePadding[2]};
            background: ${theme.color.bg};
            z-index: 999;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: ${theme.pagePadding[3]};
            padding-right: ${theme.pagePadding[1]};

            @media (min-width: ${theme.mobileBreakpoint}) {
            }
          }
        `}
      >
        <div
          css={css`
            font-size: 87%;
          `}
        >
          © 2020{" "}
          <a
            href="https://bit-tuner.net/"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              color: inherit;
            `}
          >
            Bit-Tuner
          </a>
        </div>
        <div>
          <a
            href="http://www.ous.ooo/"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              width: 3.5em;
              display: block;
            `}
          >
            <SvgContainer width={45.969} height={20.364} title="-ous">
              <path
                fill="#231F20"
                d="M39.98 6.457a5.604 5.604 0 00-3.454-1.861h-6.071a5.593 5.593 0 001.083 1.861 5.602 5.602 0 004.221 1.915 1.779 1.779 0 011.783 1.782 1.783 1.783 0 01-3.564.028h-3.83a5.613 5.613 0 109.832-3.725M25.529 4.596v7.58h-3.51v-7.58h-3.591v11.171H29.12V4.596zM11.815 11.955a1.773 1.773 0 11-.001-3.547 1.773 1.773 0 01.001 3.547m0-7.359a5.586 5.586 0 00-5.296 3.812H4.597v3.497h1.905a5.585 5.585 0 105.313-7.309"
              />
              <path
                fill="#231F20"
                d="M43.67 18.065H2.298V2.297H43.67v15.768zM0 20.364h45.969V0H0v20.364z"
              />
            </SvgContainer>
          </a>
        </div>
      </footer>
      <Header>{headerTitle}</Header>
      <main
        css={css`
          position: relative;
          z-index: 99;
          padding-top: ${theme.pagePadding[0]};

          @media (min-width: ${theme.mobileBreakpoint}) {
            padding: ${theme.pagePadding.join(" ")};
          }
        `}
      >
        {children}
      </main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
