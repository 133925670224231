import React from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

const offsetRange = 8
const offsetDuration = 3000
const Player = React.memo(({ frames, pos, dim, ...props }) => {
  const [frame, setFrame] = React.useState(0)
  const [frameOffset, setFrameOffset] = React.useState(0)

  /* idle play */
  const offsetStart = React.useRef(new Date().getTime())
  const raf = React.useRef()
  React.useEffect(() => {
    offsetStart.current = new Date().getTime()
  }, [pos])
  React.useEffect(() => {
    offsetStart.current = new Date().getTime()
    let range = []
    for (let i = 0; i < offsetRange; i++) {
      range.push(i)
    }
    for (let i = offsetRange; i > -offsetRange; i--) {
      range.push(i)
    }
    for (let i = -offsetRange; i < 0; i++) {
      range.push(i)
    }
    let elapsed = 0
    const anim = () => {
      elapsed =
        (1 / offsetDuration) *
        ((new Date().getTime() - offsetStart.current) % offsetDuration)
      const i = Math.floor(elapsed * range.length)
      setFrameOffset(range[i])
      raf.current = requestAnimationFrame(anim)
    }
    anim()
    return () => {
      cancelAnimationFrame(raf.current)
    }
  }, [offsetStart])

  /* set anim position from normalized prop "pos" */
  React.useEffect(() => {
    setFrame(
      Math.max(
        0,
        Math.min(
          frames.length - 1,
          Math.round(
            pos * (frames.length - 1 - offsetRange * 2) +
              offsetRange +
              frameOffset
          )
        )
      )
    )
  }, [pos, frames, frameOffset])

  return (
    <img
      alt=""
      src={frames[frame]}
      css={[
        css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `,
        dim
          ? css`
              width: ${dim.width}px;
              height: ${dim.height}px;
            `
          : null,
      ]}
    />
  )
})

export default Player
