/** @jsx jsx */
import { css, jsx } from "@emotion/core"

const SvgContainer = ({ width, height, title, children, ...props }) => {
  return (
    <div
      {...props}
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: ${(100 / width) * height}%;
      `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        css={css`
          display: block;
          overflow: visible;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        `}
      >
        {title && <title>{title}</title>}
        {children}
      </svg>
    </div>
  )
}

export default SvgContainer
