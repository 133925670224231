/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import AudioPlayer from ".//AudioPlayer"
import SequenceHolder from "./sequence/Holder"

const Nav = ({ items }) => {
  return (
    <nav
      css={theme => css`
        display: flex;
        overflow: auto;
        height: 100%;
        align-items: stretch;
        padding-left: ${theme.pagePadding[3]};
        padding-right: ${theme.pagePadding[1]};
        text-transform: uppercase;
        font-weight: bold;
      `}
    >
      {items &&
        items.map(item => (
          <button
            key={item.title}
            onClick={() => {
              item.ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }}
            css={theme => [
              theme.resetButton(theme),
              css`
                cursor: pointer;
                text-transform: inherit;
                padding: 0 1em;
                border-top: 0.2em solid
                  ${item.visible ? "currentColor" : "transparent"};

                &:first-of-type {
                  margin-left: -1em;
                }

                @media (max-width: 40em) {
                  &:before {
                    content: "·";
                  }
                }
              `,
            ]}
          >
            <span
              css={css`
                @media (max-width: 40em) {
                  display: none;
                }
              `}
            >
              {item.title}
            </span>
          </button>
        ))}

      <SequenceHolder
        css={css`
          margin-left: auto;
        `}
      />
      <AudioPlayer />
    </nav>
  )
}

export default Nav
