import React from "react"
import { createPortal } from "react-dom"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { motion, AnimatePresence } from "framer-motion"

import MenuButton from "./MenuButton.js"

const Button = ({ id, title, src, offset, isCurrent, ...props }) => {
  const variants = {
    initial: { y: "-100%", opacity: 0 },
    animate: {
      y: "0%",
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      y: "100%",
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  }
  return (
    <motion.div
      key={id}
      variants={variants}
      css={css`
        position: relative;
        flex: 1 1 7.2em;
        z-index: ${isCurrent ? 1 : 0};
        margin: -1px;
      `}
      custom={{ isCurrent }}
    >
      <button
        css={theme => [
          theme.resetButton(theme),
          css`
            font-size: 87.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 100%;
            cursor: pointer;
            border: 0.5em solid ${isCurrent ? theme.color.bg : theme.color.fg};
            white-space: nowrap;
            padding: 1em 2em;
            background-color: ${theme.color.bg};
          `,
          src
            ? css`
                background-color: ${theme.color.special};
                background-image: url(${src});
                background-size: cover;
              `
            : css`
                background-color: ${theme.color.fg};
              `,
        ]}
        {...props}
        title={title}
      >
        {!src ? title : ""}
      </button>
    </motion.div>
  )
}

const Picker = ({
  items,
  icon,
  iconText,
  onPick,
  currentPicked,
  onOpen,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [current, setCurrent] = React.useState(currentPicked)

  React.useEffect(() => {
    if (onPick) onPick(current)
  }, [current, onPick])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (setIsOpen) setIsOpen(false)
    }, 400)
    return () => {
      clearTimeout(timeout)
    }
  }, [current, onPick])

  const modal = {
    initial: {
      opacity: 0,
      //y: "-100%"
    },
    animate: {
      opacity: 1,
      //y: "0%",
      transition: {
        staggerChildren: 0.05,
      },
    },
    exit: {
      opacity: 0,
      //y: "100%",
      transition: {
        staggerChildren: 0.05,
      },
    },
  }

  const onClickButton = React.useCallback(() => {
    if (typeof onOpen === "function") {
      onOpen()
    }
    setIsOpen(true)
  }, [onOpen])

  return (
    <>
      <MenuButton
        onClick={onClickButton}
        text={iconText}
        isActive={isOpen}
        {...props}
      >
        {icon}
      </MenuButton>

      {typeof window !== "undefined" &&
        createPortal(
          <div
            role="button"
            tabIndex={-1}
            onClick={evt => {
              if (evt.currentTarget !== evt.target) return
              setIsOpen(false)
            }}
            css={theme => css`
              pointer-events: ${isOpen ? "all" : "none"};
              cursor: ${isOpen ? "zoom-out" : "inherit"};
              position: fixed;
              z-index: 9999;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  key="modal"
                  variants={modal}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  css={theme => css`
                    padding: ${theme.pagePadding.join(" ")};
                    color: ${theme.color.bg};
                    flex: 1 1 auto;
                    display: flex;
                    flex-flow: wrap;
                    justify-content: center;
                    align-items: center;
                    justify-content: stretch;
                    align-items: stretch;
                    overflow: auto;
                    width: 100%;
                    max-width: 35.556em;
                    height: 100%;
                    max-height: 30em;
                    margin: auto;
                  `}
                >
                  {items &&
                    items.map((item, i) => {
                      return (
                        <Button
                          key={item.id}
                          id={item.id}
                          onClick={() => setCurrent(i)}
                          title={item.title}
                          src={item.src}
                          offset={i}
                          isCurrent={current === i}
                        />
                      )
                    })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>,
          document.body
        )}
    </>
  )
}

export default React.memo(Picker)
