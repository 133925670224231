/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { motion, AnimatePresence } from "framer-motion"

const Text = ({ text }) => (
  <motion.div
    initial={{ opacity: 0, y: "20%" }}
    animate={{ opacity: 1, y: "0%" }}
    exit={{ opacity: 0, y: "20%" }}
    css={css`
      font-size: 52%;
      line-height: 1;
      position: absolute;
      bottom: 0.2em;
      left: 1px;
      right: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-weight: normal;
      white-space: nowrap;
    `}
  >
    {text}
  </motion.div>
)

const Loader = ({ isActive, progress }) => (
  <motion.div
    initial={{ opacity: 0, y: "20%" }}
    animate={{ opacity: 1, y: "0%" }}
    exit={{ opacity: 0, y: "20%" }}
    css={theme =>
      css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      `
    }
  >
    <div
      style={{ width: progress * 100 + "%" }}
      css={theme =>
        css`
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 0%;
          background: ${isActive ? theme.color.bg : theme.color.fg};
        `
      }
    />
  </motion.div>
)

const MenuButton = ({
  hasLoader,
  progress,
  children,
  text,
  isActive,
  ...props
}) => {
  return (
    <button
      {...props}
      css={theme => [
        theme.resetButton(theme),
        css`
          overflow: hidden;
          box-shadow: ${isActive ? "inset" : ""} 0 0 2px
            ${isActive ? theme.color.bg : theme.color.fg};
          border-radius: 4px;
          margin: 0.4em 0 0.4em 0.4em;
          padding: 0 1.2em;
          cursor: pointer;
          position: relative;
          color: ${isActive ? theme.color.bg : theme.color.fg};
          background-color: ${!isActive ? theme.color.bg : theme.color.fg};

          &:focus,
          &:active,
          &:hover {
            & svg {
              fill: ${theme.color.special};
            }
          }

          & svg {
            display: block;
          }
        `,
      ]}
    >
      {children}
      <AnimatePresence>
        {hasLoader && progress !== 1 ? (
          <Loader key={"loader"} progress={progress} isActive={isActive} />
        ) : (
          text && <Text key={text} text={text} />
        )}
      </AnimatePresence>
    </button>
  )
}

export default MenuButton
